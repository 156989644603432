import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Navbar, Nav, Container, InputGroup, FormControl } from 'react-bootstrap';
import i18n from '../../i18n';
import { useTranslation } from 'react-i18next';
import './Navbars.css';
// import Logo from '../../images/logobg.png';

const Navbars = () => {
  const { t } = useTranslation();
  const [language, setLanguage] = useState(localStorage.getItem('language') || 'en');
  const [scrolled, setScrolled] = useState(false);
  const [expanded, setExpanded] = useState(false);

  useEffect(() => {
    // Event listener for scrolling
    window.addEventListener('scroll', handleScroll);

    // Clean up the event listener
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const handleScroll = () => {
    if (window.scrollY > 0) {
      setScrolled(true);
    } else {
      setScrolled(false);
    }
  };

  const handleLanguageChange = (e) => {
    const selectedLanguage = e.target.value;
    setLanguage(selectedLanguage);
    localStorage.setItem('language', selectedLanguage);
    i18n.changeLanguage(selectedLanguage);
  };

  const handleNavClose = () => {
    if (window.innerWidth <= 991) { // Adjust the breakpoint if needed
      setExpanded(false);
    }
  };

  return (
    <Navbar
      bg={scrolled ? 'light' : 'transparent'}
      expand="lg"
      variant={scrolled ? 'light' : 'dark'}
      fixed="top"
      className={scrolled ? 'scrolling-navbar' : ''}
      expanded={expanded}
    >
      <Container>
        <Navbar.Brand href="/">
          {/* <img src={Logo}  className="d-inline-block align-top" alt="Logo" /> */}
          <h3 className='aven'>AVENUE 23</h3>
          <h4 className='butiqe'>BOUTIQUE HOTEL</h4>
        </Navbar.Brand>
        <Navbar.Toggle
          aria-controls="responsive-navbar-nav"
          onClick={() => setExpanded(!expanded)}
        />
        <Navbar.Collapse id="responsive-navbar-nav">
        <Nav className="mx-auto">
            {/* <Link to="/" className='nav-link' onClick={handleNavClose}>
              {t("home")}
            </Link> */}
            <Link to="/rooms" className='nav-link' onClick={handleNavClose}>
              {t("rooms")}
            </Link>
            <Link to="/about" className='nav-link' onClick={handleNavClose}>
              {t("about")}
            </Link>
            <Link to="/bar" className='nav-link' onClick={handleNavClose}>
              {t("bar")}
            </Link>
            <Link to="/cafe" className='nav-link' onClick={handleNavClose}>
              {t("cafe")}
            </Link>
            <Link to="/turs" className='nav-link' onClick={handleNavClose}>
              {t("tours")}
            </Link>
        </Nav>
        <Nav>
  <InputGroup className="language-select-container">
    <FormControl
      as="select"
      className="language-select"
      value={language}
      onChange={handleLanguageChange}
      
    >
      <option value="en">English</option>
      <option value="ru">Русский</option>
      <option value="am">Հայերեն</option>
    </FormControl>
   
  </InputGroup>
</Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default Navbars;
