import React, { lazy, Suspense, useEffect } from "react";
import { HashRouter, Route, Routes, useLocation } from "react-router-dom";
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'animate.css/animate.css';
import CardComp from './copmonents/CardComp/CardComp';
import BarCoffe from './copmonents/BarCoffe/BarCoffe';
import Navbars from './copmonents/NavBar/Navbars';

const CaruselS = lazy(() => import('./copmonents/CaruselS/CaruselS'));
const FirstSection = lazy(() => import('./copmonents/FirstSection/FirstSection'));
const SecondSection = lazy(() => import('./copmonents/SecondSection/SecondSection'));
const ArmTurizm = lazy(() => import('./copmonents/ArmTurizm/ArmTurizm'));
const Footer = lazy(() => import('./copmonents/Footer/Footer'));
const InfoRoom = lazy(() => import('./copmonents/InfoRoom/InfoRoom'));
const About = lazy(() => import('./copmonents/About/About'));
const HotelInfo = lazy(() => import('./copmonents/HotelInfo/HotelInfo'));
const Map = lazy(() => import('./copmonents/Map/Map'));
const RoomsSlid = lazy(() => import('./copmonents/RoomsSlid/RoomsSlid'));
const TurArm = lazy(() => import('./copmonents/TurArm/TurArm'));
const AirCofe = lazy(() => import('./copmonents/AirCofe/AirCofe'));
const BarComponent = lazy(() => import('./copmonents/BarComponent/BarComponent'));
const RoomSlideTwo = lazy(() => import('./copmonents/RoomsSlid/RoomImages/RoomSlideTwo/RoomSlideTwo'));
const RoomSlideThree = lazy(() => import('./copmonents/RoomsSlid/RoomImages/RoomSlideThree/RoomSlideThree'));
const RoomSlideFore = lazy(() => import('./copmonents/RoomsSlid/RoomImages/RoomSlideFore/RoomSlideFore'));
const RoomSlideFive = lazy(() => import('./copmonents/RoomsSlid/RoomImages/RoomSlideFive/RoomSlideFive'));
const RoomSlideSix = lazy(() => import('./copmonents/RoomsSlid/RoomImages/RoomSlideSix/RoomSlideSix'));
const RoomSlideSeven = lazy(() => import('./copmonents/RoomsSlid/RoomImages/RoomSlideSeven/RoomSlideSeven'));
const RoomSlideEigth = lazy(() => import('./copmonents/RoomsSlid/RoomImages/RoomSlideEigth/RoomSlideEigth'));

function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

function App() {
  return (
    <HashRouter>
      <ScrollToTop />
      <Navbars />
      <Routes>
        <Route path="/" element={
          <Suspense fallback={<div>Loading...</div>}>
            <CaruselS />
            <FirstSection />
            <SecondSection />
            <CardComp />
            <BarCoffe />
            <ArmTurizm />
          </Suspense>
        } />
        <Route path="/rooms" element={
          <Suspense fallback={<div>Loading...</div>}>
            <SecondSection />
            <InfoRoom />
          </Suspense>
        } />
        <Route path="/about" element={
          <Suspense fallback={<div>Loading...</div>}>
            <About />
            <FirstSection />
            <HotelInfo />
            <Map />
          </Suspense>
        } />
        <Route path="/room1" element={
          <Suspense fallback={<div>Loading...</div>}>
            <RoomsSlid />
            <CardComp />
            <InfoRoom />
          </Suspense>
        } />
        <Route path="/room2" element={
          <Suspense fallback={<div>Loading...</div>}>
            <RoomSlideTwo />
            <CardComp />
            <InfoRoom />
          </Suspense>
        } />
        <Route path="/room3" element={
          <Suspense fallback={<div>Loading...</div>}>
            <RoomSlideThree />
            <CardComp />
            <InfoRoom />
          </Suspense>
        } />
        <Route path="/room4" element={
          <Suspense fallback={<div>Loading...</div>}>
            <RoomSlideFore />
            <CardComp />
            <InfoRoom />
          </Suspense>
        } />
        <Route path="/room5" element={
          <Suspense fallback={<div>Loading...</div>}>
            <RoomSlideFive />
            <CardComp />
            <InfoRoom />
          </Suspense>
        } />
        <Route path="/room6" element={
          <Suspense fallback={<div>Loading...</div>}>
            <RoomSlideSix />
            <CardComp />
            <InfoRoom />
          </Suspense>
        } />
         <Route path="/room7" element={
          <Suspense fallback={<div>Loading...</div>}>
            <RoomSlideSeven />
            <CardComp />
            <InfoRoom />
          </Suspense>
        } />
         <Route path="/room8" element={
          <Suspense fallback={<div>Loading...</div>}>
            <RoomSlideEigth />
            <CardComp />
            <InfoRoom />
          </Suspense>
        } />
        <Route path="/turs" element={
          <Suspense fallback={<div>Loading...</div>}>
            <TurArm />
          </Suspense>
        } />
        <Route path="/bar" element={
          <Suspense fallback={<div>Loading...</div>}>
            <BarComponent />
          </Suspense>
        } />
        <Route path="/cafe" element={
          <Suspense fallback={<div>Loading...</div>}>
            <AirCofe />
          </Suspense>
        } />
      </Routes>
      <Suspense fallback={<div>Loading...</div>}>
        <Footer />
      </Suspense>
    </HashRouter>
  );
}

export default App;
