import React,{useEffect} from 'react';
import Aos from "aos";
import 'aos/dist/aos.css';
import { Container, Row, Col, Card } from 'react-bootstrap';
import '@fortawesome/fontawesome-free/css/all.min.css';
import './CardComp.css';
import { useTranslation } from 'react-i18next';

const YourComponent = () => {
  const { t } = useTranslation();

  useEffect(() => {
    Aos.init({duration: 2000})
  },[])
  return (
    <Container className='vcard'>
      <h2  data-aos='fade-up' data-aos-duration='4000' className="custom-heading">{t("heading")}</h2>
      <Row className="card-row">
      <Col  data-aos='fade-up' data-aos-duration='2000' md={4}>
          <Card className="cardt">
            <Card.Body>
              <div className="icon-wrapper">
              <i className="fas fa-plane-arrival"></i>
              </div>
              <Card.Title className="custom-title">{t("pick")}</Card.Title>
              <Card.Text>{t("pickdesc")}</Card.Text>
            </Card.Body>
          </Card>
        </Col>
        <Col  data-aos='fade-up' data-aos-duration='2000' md={4}>
          <Card className="cardt">
            <Card.Body>
              <div className="icon-wrapper">
              <i className="fas fa-car-rear"></i>
              </div>
              <Card.Title className="custom-title">{t("parking")}</Card.Title>
              <Card.Text>{t("parkingdesc")}</Card.Text>
            </Card.Body>
          </Card>
        </Col>
        <Col  data-aos='fade-up' data-aos-duration='2000' md={4}>
          <Card className="cardt">
            <Card.Body>
              <div className="icon-wrapper">
                <i className="fas fa-bed"></i>
              </div>
              <Card.Title className="custom-title">{t("rservice")}</Card.Title>
              <Card.Text>{t("rservicedesc")}</Card.Text>
            </Card.Body>
          </Card>
        </Col>
        <Col  data-aos='fade-up' data-aos-duration='2000' md={4}>
          <Card className="cardt">
            <Card.Body>
              <div className="icon-wrapper">
              <i className="fas fa-martini-glass-citrus"></i>
              </div>
              <Card.Title className="custom-title">{t("drinks")}</Card.Title>
              <Card.Text>{t("drinksdesc")}</Card.Text>
            </Card.Body>
          </Card>
        </Col>
        <Col  data-aos='fade-up' data-aos-duration='2000' md={4}>
          <Card className="cardt">
            <Card.Body>
              <div className="icon-wrapper">
              <i className="fas fa-wifi"></i>
              </div>
              <Card.Title className="custom-title">{t("internet")}</Card.Title>
              <Card.Text>{t("internetdesc")}</Card.Text>
            </Card.Body>
          </Card>
        </Col>
        <Col  data-aos='fade-up' data-aos-duration='2000' md={4}>
          <Card className="cardt">
            <Card.Body>
              <div className="icon-wrapper">
              <i className="fas fa-utensils"></i>
              </div>
              <Card.Title className="custom-title">{t("breakfast")}</Card.Title>
              <Card.Text>{t("breakfastdesc")}</Card.Text>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default YourComponent;
