import React, {useEffect} from 'react';
import { Link } from 'react-router-dom';
import Aos from "aos";
import 'aos/dist/aos.css';
import { Container, Row, Col } from 'react-bootstrap';
import './BarCoffe.css';
import BarImage from '../../images/CaruselS/barimage.jpg';
import CaffeImage from '../../images/CaruselS/streetcafe.avif';
import { useTranslation } from 'react-i18next';

const BarCoffe = () => {

  const { t } = useTranslation();

  useEffect(() => {
    Aos.init({duration: 2000})
  },[])
  return (
    <Container className='cont'>
      <Row className="my-4">
        <Col data-aos='fade-up' data-aos-duration='2000' sm={12} md={6} className="bar-col">
          <img src={BarImage} alt="Bar" className="img-fluid" />
        </Col>
        <Col data-aos='fade-up' data-aos-duration='2000' sm={12} md={6} className="d-flex align-items-center justify-content-center">
          <div className="content">
            <h3 className="custom-text">{t("bhotel")}</h3>
            <h2 className="custom-rest">{t("barr")}</h2>
            <p className="custom-parag">{t("barrdesc")}</p>
            <Link className="custom-link" to="/bar">{t("more")}</Link>
          </div>
        </Col>
      </Row>
      <Row className="my-4">
        <Col data-aos='fade-up' data-aos-duration='2000' sm={12} md={6} className="d-flex align-items-center justify-content-center">
          <div className="content">
            <h3 className="custom-text">{t("bhotel")}</h3>
            <h2 className="custom-rest">{t("openC")}</h2>
            <p className="custom-parag">{t("openCdesc")}</p>
            <Link className="custom-link" to="/cafe">{t("more")}</Link>
          </div>
        </Col>
        <Col data-aos='fade-up' data-aos-duration='2000' sm={12} md={6} className="cafe-col">
          <img src={CaffeImage} alt="Cafe" className="img-fluid" />
        </Col>
      </Row>
    </Container>
  );
};

export default BarCoffe;
