import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

const lastLanguage = localStorage.getItem('language') || 'en';
i18n
  .use(initReactI18next)
  .init({
    debug: true,
    fallbackLng: 'en',
    lng: lastLanguage, // Set the initial language to the last selected language
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    resources: {
        en: {
          translation: {
            home: 'Home',
            about: 'About',
            services: 'Services',
            rooms: 'Rooms',
            bar:'Bar',
            cafe:'Cafe',
            tours:'Tours in Armenia',
            roomesandsuites:`ROOMS & SUITES`,
            unique:`UNIQUE PLACE TO RELAX & ENJOY`,
            reservation:`Reservation`,
            bhotel:`A23 BOUTIQUE HOTEL`,
            luxury1:`Enjoy a Luxury`,
            luxury2:`Holiday`,
            hotinfo1:`Avenue 23 Boutique Hotel is a charming boutique hotel located in the heart of Yerevan city. It offers a unique blend of comfort, elegance, and personalized service. The hotel provides convenient access to many attractions, shopping centers, and dining options in Yerevan.

            The hotel features beautifully designed rooms and suites, each meticulously decorated to provide a comfortable and luxurious stay for guests. Whether you're visiting for business or leisure, Avenue 23 Boutique Hotel offers a tranquil retreat in the bustling city center.`,
            hotinfo2:` The hotel's attentive staff is dedicated to ensuring that guests have a memorable and enjoyable stay, providing assistance with anything from restaurant recommendations to arranging transportation.

            With its central location and charming ambiance, Avenue 23 Boutique Hotel is an ideal choice for travelers looking to experience the beauty and hospitality of Yerevan.`,
            dram:`AMD / Night`,
            standart:`Standart`,
            deluxe:`De Luxe `,
            luxe:` Luxe `,
            details:`Details`,
            tourarm:`We offer a full range of services for inbound tourism.`,
            tourarm1:`Development of thematic tours and exclusive solutions for our clients in accordance with their specific requirements. A top priority for us is the constant updating of the range of offers in accordance with tendencies of market development.`,
            contact:`Contact`,
            explore:`Explore`,
            armtur1:`Explore the beauty of Armenia with our guided tours. Whether you're interested in historical sites, natural landscapes, or cultural experiences, we have the perfect tour for you. Join us for an unforgettable journey through the heart of Armenia.`,
            armtur2:`Available Tours`,
            noravank:`Noravank and Khor Virap Tour`,
            noravankdisc:`Wine degustation,a wonderful place from where you can admire the picturesque views of the Holy Mount Ararat`,
            priceD:`Price: AMD`,
            garni:`Garni Temple and Geghard Monastery`,
            garnidesc:`Visit  iconic historical sites,the first visit to the pagan temple of Garni (1st century) Dedicated to the sun god Mitra`,
            sevan:`Lake Sevan Excursion`,
            sevandesc:`Enjoy the breathtaking views of Lake Sevan`,
            tatev:`Tatev Monastery, Khndzoresk, Shaki waterfall`,
            tatevdesc:`The tour begins with a visit to the Tatev Monastery, which at one time was a spiritual center of outstanding scientific and political significance`,
            zarni:`Zarni-Parni, Sanahin, Haghpat, Odzun`,
            zarnidesc:`The tour starts with a visit to Haghpat Monastery (10th century), which is included in the list of UNESCO World Heritage Sites`,
            gyumri:`Gyumri (city walk), Sev Berd, Harichavank`,
            gyumridesc:`Visit to the second largest city of Armenia Gyumri, which is one of the main cultural centers of the republic`,
            custom:`Custom Tours`,
            customdesc:`Our threads can help you make a plan to visit the most interesting and beautiful places in the territory of Armenia`,
            agree:`Price: By agreement`,
            armstory:`Research and Destination Selection: Start by researching the various destinations and attractions in Armenia. Consider the historical sites, natural landscapes, cultural experiences, and other points of interest that you'd like to explore. Some popular destinations in Armenia include Yerevan (the capital city), Lake Sevan, Geghard Monastery, Tatev Monastery, Dilijan National Park, and the ancient city of Echmiadzin. Duration and Timing: Determine the duration of your tour and the best time to visit Armenia. The country experiences four distinct seasons, with summers being warm and sunny, and winters being cold with opportunities for winter sports. Spring (April to June) and autumn 
                      (September to November) are generally considered the best seasons for visiting Armenia, as the weather is pleasant and nature is at its most vibrant.`,
            pick:`Pick Up & Drop`,
            pickdesc:`This includes picking up guests from places like airports or train stations and dropping them off at their destination`, 
            parking:`Parking Space`,
            parkingdesc:`Guests can park their vehicles, increasing the overall comfort  of guests arriving by private vehicle`,  
            rservice:`Room Service`, 
            rservicedesc:`Guests can order room service. Cleaning maintains a clean environment in rooms and common areas`, 
            drinks:`Drinks and alcohol`, 
            drinksdesc:`Guests can enjoy drinks and alcohol from the bar, Open-Air Café, or room service. `, 
            internet:`Free Internet`, 
            internetdesc:`Free internet access is a standard amenity, available via Wi-Fi in guest rooms and common areas`,  
            breakfast:`Breakfast`,
            breakfastdesc:`Breakfast includes pastries to hot dishes to satisfy and energize guests at the start of their day.`,
            barr:`Convenient Bar`,
            barrdesc:`A convenient bar  provides guests with a relaxed setting to enjoy a variety of beverages, including alcoholic and non-alcoholic options. It offers a convenient spot for guests to unwind after a day of exploring or to socialize with fellow travelers. The bar may feature a selection of local and international
                     drinks, cocktails, and light snacks, allowing guests to indulge in their favorite drinks without having to leave the hotel premises`,
            more:`LEARN MORE`,
            openC:`Open-Air Café`,
            openCdesc:`An open-air café  provides guests with an outdoor dining experience, allowing them to enjoy meals, snacks, and drinks in a pleasant outdoor setting. It offers a refreshing alternative to indoor dining, allowing guests to soak in the surroundings while indulging in delicious food and beverages. 
                       It provides guests with the opportunity to enjoy the weather and connect with nature while enjoying the hotel's hospitality`,
            adress:`Sayat Nova Avenue 23, Yerevan`,
            heading:`You Are Offered`,
            hotelinform:`"Welcome to our boutique hotel located in the vibrant center of Yerevan! We take pride in offering our guests a comfortable and delightful stay in our beautifully appointed rooms. Each room is meticulously designed to provide a cozy and inviting atmosphere, ensuring you feel right at home during your visit.

            Indulge in the convenience of our in-room amenities, including a well-stocked mini bar, perfect for enjoying your favorite beverages at any time. Additionally, our spacious and modern bathrooms feature all the essentials you need, including a toilet and a refreshing shower.
            
           "`,
           roominform:`"Welcome to Our Hotel! Experience comfort and convenience at our charming hotel, where every detail is tailored to ensure a delightful stay for our guests. Our cozy rooms accommodate 2 to 3 people, ideal for couples or small families, with check-in at 2:00 PM and check-out at 12:00 PM. Enjoy complimentary high-speed Wi-Fi, in café & in bar. Conveniently located in the heart ofYerevan, our hotel offers easy access to local attractions, shopping, and dining options. We look forward to welcoming you and ensuring you have a pleasant stay."`,
           rinfo:`Room Information`,
           occupy:`Max Occupancy`,
           checkin:`Check-In: 2:00 PM`,
           chekout:`Check-Out: 12:00 PM`,
           free:`Free`,
           included:`Breakfast  included`,
           hotelinfo:`Hotel Information`,
           CB:`Café & Bar`,
           inroom:`In-room Dining`,
           daily:`Daily Housekeeping`,
           center:`Central Location`,
           tel:`Phone`,
           abus:`ABOUT US`,
           facilit:`Facilities`,
           amenities:`Amenities`,
           condicioner:`Air Conditioning`,
           minib:`Mini Bar`,
           location:`Location`,
           adressx:`Address`,
           menu:`Menu`,
           cocteils:`Cocktails`,
           moxito:`Mojito`,
           margarita:`Margarita`,
           cosmo:`Cosmopolitan`,
           espmar:`Espresso Martini`,
           desert:`Desserts`,
           brauni:`Chocolate Brownie`,
           pirog:`Fruit Tart`,
           vanil:`Vanilla Ice Cream`,
           chokolad:`Chocolate Ice Cream`,
           Strawberry:`Strawberry Ice Cream`,
           hotdr:`Hot Beverages`,
           espreso:`Espresso`,
           americano:`Americano`,
           Cappuccino:`Cappuccino`,
           Latte:`Latte`,
           enlishT:`English Breakfast Tea`,
           greenT:`Green Tea`,
           nonAlc:`Non-Alcoholic`,
           orangeJ:`Orange Juice`,
           appleJ:`Apple Juice`,
           ananas:`Pineapple juice`,
           welc:`Welcome to`,
           infobintr:`Nestled in the heart of Yerevan's vibrant city center, Avenue 23 Boutique Hotel offers a charming retreat for discerning travelers. Step into our nice space where contemporary sophistication meets Armenian hospitality.

                      Whether you're seeking a quiet evening or a lively gathering with friends, Avenue 23 invites you to create lasting memories in the heart of the city.`,
           bacK:`Welcome to Our Open-Air Café`, 
           injoi:`Enjoy a relaxing atmosphere and delicious food right in front of our hotel!`,
           delishos:`Our Delicious Menu`,
           closeMenu:`Close Menu`,
           openMenu:`Open Menu`,
           lunch:`Lunch`,
           dinner:`Dinner`,
           Sausages:`Sausages`,
           Fried:`Fried eggs`,
           Light:`Light salads`,
           Pancakes:`Pancakes`,
           Grilled:`Grilled Chicken Breast`,
           Pasta:`Pasta`,
           Salads:`Salads`,
           Pizzas:`Pizzas`,
           Sandwiches:`Sandwiches`,
           Caesar:`Caesar Salad`,
           adventure:`Start Your Adventure From Here`,
           LHBT:`LUXURY HOTEL & BEST TOURS`,
           moments:`ENJOY THE BEST MOMENTS OF LIFE`,

          },
        },
        ru: {
          translation: {
            home: 'Домашняя',
            about: 'О нас',
            services: 'Услуги',
            rooms: 'Номера',
            bar:'Бар',
            cafe:'Кафе',
            tours:'Туры по Армении',
            roomesandsuites:`НОМЕРА И ЛЮКСЫ`,
            unique:`УНИКАЛЬНОЕ МЕСТО ДЛЯ ОТДЫХА И НАСЛАЖДЕНИЯ`,
            reservation:`Бронирование`,
            bhotel:`А23 БУТИК ОТЕЛЬ`,
            luxury1:`Наслаждайтесь Роскошным`,
            luxury2:`Отдыхом`,
            hotinfo1:`Бутик отель «Avenue 23» — очаровательный бутик-отель, расположенный в самом центре Еревана. Он предлагает уникальное сочетание комфорта, элегантности и индивидуального обслуживания. Отель обеспечивает удобный доступ ко многим достопримечательностям, торговым центрам и ресторанам Еревана.

            В отеле есть красиво оформленные номера и люксы, каждый из которых тщательно оформлен, чтобы обеспечить гостям комфортное и роскошное пребывание. Если вы приехали по делам или на отдых, бутик-отель Avenue 23 предлагает спокойный отдых в оживленном центре города.`,
            hotinfo2:`Внимательный персонал отеля позаботится о том, чтобы пребывание гостей было незабываемым и приятным, оказывая помощь во всем: от рекомендаций ресторанов до организации транспорта.

            Бутик отель Avenue 23 благодаря своему центральному расположению и очаровательной атмосфере является идеальным выбором для путешественников, желающих ощутить красоту и гостеприимство Еревана.`,
            dram:`Драм / Ночь`,
            standart:`Стандарт`,
            deluxe:`Де Люкс`,
            luxe:`Люкс`,
            details:`Подробности`,
            tourarm:`Мы предлагаем полный спектр услуг для въездного туризма.`,
            tourarm1:`Разработка тематических туров и эксклюзивных решений для наших клиентов в соответствии с их конкретными требованиями. Главным приоритетом для нас является постоянное обновление ассортимента предложений в соответствии с тенденциями развития рынка.`,
            contact:`Контакт`,
            explore:`Исследовать`,
            armtur1:`Откройте для себя красоту Армении с нашими экскурсиями. Если вас интересуют исторические места, природные ландшафты или культурный опыт, у нас есть идеальный тур для вас. Присоединяйтесь к нам в незабываемом путешествии по сердцу Армении.`,
            armtur2:`Доступные Туры`,
            noravank:`Тур в Нораванк и Хор Вирап`,
            noravankdisc:`Дегустация вин, чудесное место, откуда можно полюбоваться живописным видом на Святую гору Арарат`,
            priceD:`Цена: Драм`,
            garni:`Храм Гарни и монастырь Гегард`,
            garnidesc:`Посещение знаковых исторических мест, первое посещение языческого храма Гарни (I век), посвященного богу Солнца Митре`,
            sevan:`Экскурсия по озеру Севан`,
            sevandesc:`Насладитесь захватывающим видом на озеро Севан`,
            tatev:`Татевский монастырь, Хндзореск, Шекинский водопад`,
            tatevdesc:`Экскурсия начинается с посещения Татевского монастыря, который в свое время был духовным центром выдающегося научного и политического значения`,
            zarni:`Зарни-Парни, Санаин, Ахпат, Одзун`,
            zarnidesc:`Экскурсия начинается с посещения монастыря Ахпат (10 век), который включен в список объектов Всемирного наследия ЮНЕСКО`,
            gyumri:`Гюмри (прогулка по городу), Сев Берд, Аричаванк`,
            gyumridesc:`Посещение второго по величине города Армении Гюмри, который является одним из главных культурных центров республики`,
            custom:`Индивидуальные туры`,
            customdesc:`Наши темы помогут вам составить план посещения самых интересных и красивых мест на территории Армении`,
            agree:`Цена: По договоренности`,
            armstory:`Исследование и выбор направления: Начните с изучения различных направлений и достопримечательностей Армении. Подумайте об исторических местах, природных ландшафтах, культурном опыте и других достопримечательностях, которые вы хотели бы изучить. Некоторые популярные направления в Армении включают Ереван (столицу), озеро Севан, монастырь Гегард, монастырь Татев, Дилижанский национальный парк и древний город Эчмиадзин. Продолжительность и время: Определите продолжительность вашего тура и лучшее время для посещения Армении. В стране четыре разных сезона: лето теплое и солнечное, а зима холодная и дает возможность заниматься зимними видами спорта. Весна (с апреля по июнь) и осень (с сентября по ноябрь)
                       обычно считаются лучшими сезонами для посещения Армении, поскольку погода приятная, а природа самая яркая.`,
            pick:`Встреча и Высадка`,
            pickdesc:`Это включает в себя встречу гостей в таких местах, как аэропорты или вокзалы, и доставку их в пункт назначения`, 
            parking:`Парковочное место`, 
            parkingdesc:`Гости могут парковать свои транспортные средства, повышающая общий комфорт и удобство гостей, приезжающих на личном транспорте`, 
            rservice:`Обслуживание номеров`,  
            rservicedesc:`Гости могут заказать доставку блюд и услуг в номер. Уборка обеспечивает чистоту и комфорт в номерах и местах общего пользования`, 
            drinks:`Напитки и алкоголь`,
            drinksdesc:`Гости могут насладиться напитками и алкоголем в баре, кафе под открытым небом или воспользоваться обслуживанием номеров`, 
            internet:`Бесплатный Интернет`,
            internetdesc:`Бесплатный доступ в Интернет является стандартной услугой, доступной через Wi-Fi в номерах и местах общего пользования`,   
            breakfast:`Завтрак`,  
            breakfastdesc:`Завтрак включает в себя выпечку и горячие блюда, чтобы удовлетворить гостей и зарядить их энергией в начале дня`,   
            barr:`Удобный Бар`,
            barrdesc:`В удобном баре гости смогут насладиться разнообразными напитками, включая алкогольные и безалкогольные, в непринужденной обстановке. Это удобное место для гостей, чтобы расслабиться после дня прогулок или пообщаться с попутчиками. В баре может быть представлен широкий выбор местных и международных напитков, 
                      коктейлей и легких закусок, что позволит гостям насладиться любимыми напитками, не покидая территорию отеля`,
            more:`УЗНАТЬ БОЛЬШЕ`,
            openC:`Открытое Кафе`,
            openCdesc:`Кафе под открытым небом предоставляет гостям возможность пообедать на свежем воздухе, позволяя им насладиться едой, закусками и напитками в приятной обстановке на открытом воздухе. Он предлагает освежающую альтернативу обеду в помещении, позволяя гостям расслабиться на свежем воздухе, наслаждаясь вкусной едой и напитками. 
                        Это дает гостям возможность насладиться погодой и соединиться с природой, наслаждаясь гостеприимством отеля`,
            adress:`Проспект Саят-Новы 23, Ереван`,
            heading:`Вам Предлагается`,
            hotelinform:`Добро пожаловать в наш бутик-отель, расположенный в оживленном центре Еревана! Мы гордимся тем, что предлагаем нашим гостям комфортное и восхитительное пребывание в наших прекрасно оборудованных номерах. Каждый номер тщательно спроектирован так, чтобы создать уютную и гостеприимную атмосферу, гарантируя, что вы почувствуете себя как  дома во время вашего визита.

            Побалуйте себя удобствами в номере, включая хорошо укомплектованный мини-бар, который идеально подходит для того, чтобы в любое время насладиться любимыми напитками. Кроме того, в наших просторных и современных ванных комнатах есть все необходимое, включая туалет и освежающий душ.
            
            `,
            roominform:`«Добро пожаловать в наш отель! Ощутите комфорт и удобство в нашем очаровательном отеле, где каждая деталь продумана для обеспечения приятного пребывания наших гостей. Наши уютные номера вмещают 2–3 человека и идеально подходят для пар или небольших семей. Регистрация заезда осуществляется в 14:00 и выезд в 12:00.Наслаждайтесь бесплатным высокоскоростным Wi-Fi в кафе и в баре.Наш отель, удобно расположенный в самом центре Еревана, предлагает легкий доступ к местным достопримечательностям, магазинам и ресторанам. Мы с нетерпением ждем возможности приветствовать вас и обеспечить вам приятное пребывание».`,
            rinfo:`Информация о номере`,
            occupy:`Макс. вместимость`,
            checkin:`Заезд: 14:00`,
           chekout:`Выезд: 12:00`,
           free:`Бесплатный`,
           included:`Завтрак включен`,
           hotelinfo:`Информация об отеле`,
           CB:`Кафе и Бар`,
           inroom:`Обед в номере`,
           daily:`Ежедневная уборка`,
           center:`Центральное расположение`,
           tel:`Телефон`,
           abus:`О НАС`,
           facilit:`Возможности`,
           amenities:`Удобства`,
           condicioner:`Кондиционер`,
           minib:`Мини-бар`,
           location:`Расположение`,
           adressx:`Адрес`,
           menu:`Меню`,
           cocteils:`Коктейли`,
           moxito:`Мохито`,
           margarita:`Маргарита`,
           cosmo:`Космополитен`,
           espmar:`Эспрессо Мартини`,
           desert:`Десерты`,
           brauni:`Шоколадный Брауни`,
           pirog:`Фруктовый пирог`,
           vanil:`Ванильное мороженное`,
           chokolad:`Шоколадное мороженое`,
           Strawberry:`Клубничное мороженое`,
           hotdr:`Горячие напитки`,
           espreso:`Эспрессо`,
           americano:`Американо`,
           Cappuccino:`Капучино`,
           Latte:`Латте`,
           enlishT:`Английский завтрак чай`,
           greenT:`Зеленый чай`,
           nonAlc:`Безалкогольный`,
           orangeJ:`Апельсиновый сок`,
           appleJ:`Яблочный сок`,
           ananas:`Ананасовый сок`,
           welc:`Добро пожаловать в`,
           infobintr:`Бутик-отель Avenue 23, расположенный в самом сердце оживленного центра города Еревана, предлагает очаровательное место отдыха для взыскательных путешественников. Зайдите в наше прекрасное пространство, где современная изысканность сочетается с армянским гостеприимством.

                      Ищете ли вы тихий вечер или оживленную встречу с друзьями, Avenue 23 приглашает вас оставить незабываемые воспоминания в самом центре города.`,
           bacK:`Добро пожаловать в наше Летнее Кафе`,
           injoi:`Наслаждайтесь расслабляющей атмосферой и вкусной едой прямо перед нашим отелем!`,
           delishos:`Наше Вкусное Меню`,
           closeMenu:`Закрыть Меню`,
           openMenu:`Открыть Меню`,
           lunch:`Обед`,
           dinner:`Ужин`,
           Sausages:`Колбасные изделия`,
           Fried:`Яичница`,
           Light:`Легкие салаты`,
           Pancakes:`Блины`,
           Grilled:`Куриная грудка на гриле`,
           Pasta:`Макаронные изделия`,
           Salads:`Салаты`,
           Pizzas:`Пицца`,
           Sandwiches:`Сэндвичи`,
           Caesar:`Салат Цезарь`,
           adventure:`Начинай Свое Приключение Отсюда`,
           LHBT:`РОСКОШНЫЙ ОТЕЛЬ И ЛУЧШИЕ ТУРЫ`,
           moments:`Наслаждайся ЛУЧШИМИ МОМЕНТАМИ ЖИЗНИ`,

          },
        },
        am: {
          translation: {
            home: 'Գլխավոր',
            about: 'Մեր մասին',
            services: 'Ծառայություններ',
            rooms: 'Համարներ',
            bar:'Բար',
            cafe:'Սրճարան',
            tours:'Տուրեր Հայաստանում',
            roomesandsuites:`ՍԵՆՅԱԿՆԵՐ ԵՎ ԼՅՈՒՔՍԵՐ`,
            unique:`ՀԱՆԳՍՏԱՆԱԼՈՒ ԵՎ ՎԱՅԵԼԵԼՈՒ ՅՈՒՐԱՀԱՏՈՒԿ ՏԵՂ`,
            reservation:`Ամրագրում`,
            bhotel:`Ա23 ԲՈՒՏԻԿ ՀՈԹԵԼ`,
            luxury1:`Վայելե՛ք շքեղ`,
            luxury2:`Հանգիստը`,
            hotinfo1:`Avenue 23 Boutique Hotel-ը հմայիչ բուտիկ հյուրանոց է, որը գտնվում է Երևան քաղաքի սրտում: Այն առաջարկում է հարմարավետության, նրբագեղության և անհատականացված սպասարկման եզակի խառնուրդ: Հյուրանոցն ապահովում է հարմարավետ մուտք դեպի Երևանի բազմաթիվ տեսարժան վայրեր, առևտրի կենտրոններ և ճաշելու տարբերակներ:

            Հյուրանոցն ունի գեղեցիկ ձևավորված սենյակներ և լյուքսեր, որոնցից յուրաքանչյուրը մանրակրկիտ ձևավորված է հյուրերի համար հարմարավետ և շքեղ հանգիստ ապահովելու համար: Անկախ նրանից, թե դուք այցելում եք բիզնեսի կամ հանգստի նպատակով, Avenue 23 Boutique Hotel-ն առաջարկում է հանգիստ հանգիստ քաղաքի աշխույժ կենտրոնում:`,
            hotinfo2:`Հյուրանոցի ուշադիր անձնակազմը նվիրված է ապահովելու, որ հյուրերը ունենան հիշարժան և հաճելի հանգիստ՝ տրամադրելով օգնություն ցանկացած հարցում՝ սկսած ռեստորանային առաջարկներից մինչև տրանսպորտի կազմակերպում:

            Իր կենտրոնական դիրքով և հմայիչ մթնոլորտով Avenue 23 Boutique Hotel-ը իդեալական ընտրություն է ճանապարհորդների համար, ովքեր ցանկանում են զգալ Երևանի գեղեցկությունն ու հյուրընկալությունը:`,
            dram:`Դրամ / Գիշեր`,
            standart:`Ստանդարտ`,
            deluxe:`Դե Լյուքս`,
            luxe:`Լյուքս`,
            details:`Մանրամասներ`,
            tourarm:`Ներգնա զբոսաշրջության համար մենք առաջարկում ենք ծառայությունների ամբողջական շրջանակ:`,
            tourarm1:`Մեր հաճախորդների համար թեմատիկ շրջագայությունների և բացառիկ լուծումների մշակում՝ նրանց հատուկ պահանջներին համապատասխան: Մեզ համար առաջնահերթություն է շուկայի զարգացման միտումներին համապատասխան առաջարկների տեսականու մշտական թարմացումը:`,
            contact:`Կապ`,
            explore:`Հետազոտել`,
            armtur1:`Բացահայտեք Հայաստանի գեղեցկությունը մեր էքսկուրսիաներով: Անկախ նրանից, թե դուք հետաքրքրված եք պատմական վայրերով, բնական լանդշաֆտներով կամ մշակութային փորձառություններով, մենք ձեզ համար կատարյալ շրջագայություն ունենք: Միացե՛ք մեզ անմոռանալի ճանապարհորդության Հայաստանի սրտով:`,
            armtur2:`Հասանելի Շրջագայություններ`,
            noravank:`Շրջագայություն Նորավանք և Խոր Վիրապ`,
            noravankdisc:`Գինու համտես, հրաշալի վայր, որտեղից կարելի է հիանալ Սուրբ Արարատ լեռան գեղատեսիլ տեսարաններով`,
            priceD:`Գինը՝ Դրամ`,
            garni:`Գառնու տաճար և Գեղարդավանք`,
            garnidesc:`Այցելեք խորհրդանշական պատմական վայրեր, առաջին այցելությունը Գառնիի հեթանոսական տաճար (1-ին դար) նվիրված արևի աստված Միտրային`,
            sevan:`Էքսկուրսիա Սևանա լճում`,
            sevandesc:`Վայելե՛ք Սևանա լճի հիասքանչ տեսարանները`,
            tatev:`Տաթևի վանք, Խնձորեսկ, Շաքիի ջրվեժ`,
            tatevdesc:`Շրջայցը սկսվում է Տաթևի վանական համալիր այցելությամբ, որը ժամանակին եղել է գիտական և քաղաքական նշանակության հոգևոր կենտրոն`,
            zarni:`Զառնի-Պառնի, Սանահին, Հաղպատ, Օձուն`,
            zarnidesc:`Շրջայցը սկսվում է Հաղպատի վանք (10-րդ դար) այցելությամբ, որն ընդգրկված է ՅՈՒՆԵՍԿՕ-ի Համաշխարհային ժառանգության օբյեկտների ցանկում`,
            gyumri:`Գյումրի (քաղաքային զբոսանք), Սև Բերդ, Հառիճավանք`,
            gyumridesc:`Այցելություն Հայաստանի մեծությամբ երկրորդ քաղաք Գյումրի, որը հանրապետության գլխավոր մշակութային կենտրոններից է`,
            custom:`Պատվերով շրջագայություններ`,
            customdesc:`Մեր թեմաները կարող են օգնել ձեզ պլան կազմել՝ այցելելու Հայաստանի տարածքում ամենահետաքրքիր և գեղեցիկ վայրերը`,
            agree:`Գինը՝ պայմանագրով`,
            armstory:`Հետազոտություն և ուղղությունների ընտրություն. Սկսեք ուսումնասիրելով Հայաստանի տարբեր ուղղություններն ու տեսարժան վայրերը: Հաշվի առեք պատմական վայրերը, բնական լանդշաֆտները, մշակութային փորձառությունները և այլ տեսարժան վայրերը, որոնք կցանկանայիք ուսումնասիրել: Հայաստանում հայտնի ուղղություններից են Երևանը (մայրաքաղաքը), Սևանա լիճը, Գեղարդի վանքը, Տաթևի վանքը, Դիլիջան ազգային պարկը և հնագույն Էջմիածինը: Տևողություն և ժամկետներ. Որոշեք ձեր շրջագայության տևողությունը և Հայաստան այցելելու լավագույն ժամանակը: Երկիրը չորս տարբեր եղանակներ է ապրում՝ ամառները տաք և արևոտ են, իսկ ձմեռները՝ ցուրտ՝ ձմեռային սպորտի հնարավորություններով: Գարունը (ապրիլ-հունիս) և աշունը (սեպտեմբեր-նոյեմբեր)
                      ընդհանուր առմամբ համարվում են Հայաստան այցելելու լավագույն եղանակները, քանի որ եղանակը հաճելի է, իսկ բնությունը՝ ամենաաշխույժ:`,
            pick:`Դիմավորել և Ճանապարհել`,
            pickdesc:`Սա ներառում է հյուրեր ընդունել այնպիսի վայրերից, ինչպիսիք են օդանավակայաններից կամ երկաթուղային կայարաններից և իջեցնել նրանց իրենց նպատակակետ`,
            parking:`Ավտոկանգառ`, 
            parkingdesc:`Հյուրերը կարող են կայանել իրենց տրանսպորտային միջոցները, որն ավելացնում է մասնավոր տրանսպորտով ժամանած հյուրերի ընդհանուր հարմարավետությունն ու հարմարավետությունը`, 
            rservice:`Համարների սպասարկում`,
            rservicedesc:`Հյուրերը կարող են պատվիրել սենյակի սպասարկում սննդի և ծառայությունների համար: Մաքրումը ապահովում է մաքուր, հարմարավետ միջավայր հյուրասենյակներում և ընդհանուր տարածքներում`,
            drinks:`Խմիչքներ և ալկոհոլ`,
            drinksdesc:`Հյուրերը կարող են վայելել ըմպելիքներ և ոգելից խմիչքներ բարից, բացօթյա սրճարանից կամ սենյակի սպասարկումից`,
            internet:`Անվճար ինտերնետ`, 
            internetdesc:`Անվճար ինտերնետ հասանելիությունը ստանդարտ հարմարություն է, որը հասանելի է Wi-Fi-ի միջոցով հյուրասենյակներում և ընդհանուր տարածքներում`, 
            breakfast:`Նախաճաշ`,
            breakfastdesc:`Նախաճաշը ներառում է խմորեղենից մինչև տաք ուտեստներ՝ հյուրերին օրվա սկզբին գոհացնելու և եռանդ տալու համար`,
            barr:`Հարմարավետ Բար`,
            barrdesc:`Հարմարավետ բարում հանգիստ մթնոլորտում հյուրերը կարող են վայելել մի շարք խմիչքներ, ներառյալ ալկոհոլային և ոչ ալկոհոլային խմիչքներ: Սա հարմարավետ վայր է հյուրերի համար  օրվա ուսումնասիրությունից հետո հանգստանալու  կամ շփվելու ընկերների հետ: Բարը կարող է առաջարկել տեղական և միջազգային խմիչքների, 
                      կոկտեյլների և թեթև նախուտեստների լայն ընտրանի՝ թույլ տալով հյուրերին վայելել իրենց սիրելի խմիչքները՝ առանց հյուրանոցի տարածքից դուրս գալու`,
            more:`ԻՄԱՆԱԼ ԱՎԵԼԻՆ`,
            openC:`Բացօթյա Սրճարան`,
            openCdesc:`Բացօթյա սրճարանը հյուրերին հնարավորություն է տալիս ճաշել դրսում՝ թույլ տալով նրանց վայելել ուտելիք, խորտիկներ և խմիչքներ հաճելի բացօթյա միջավայրում: Այն առաջարկում է զովացուցիչ այլընտրանք ներքին ճաշկերույթին, որը թույլ է տալիս հյուրերին հանգստանալ դրսում՝ վայելելով համեղ սնունդ և խմիչքներ: 
                      Սա հյուրերին հնարավորություն է տալիս վայելել եղանակը և կապ հաստատել բնության հետ՝ վայելելով հյուրանոցի հյուրընկալությունը`,
            adress:`Երևան, Սայաթ Նովա Պողոտա 23`,
            heading:`Ձեզ Առաջարկվում է`,
            hotelinform:`Բարի գալուստ մեր բուտիկ հյուրանոց, որը գտնվում է Երևանի կենսունակ կենտրոնում: Մենք հպարտ ենք, որ մեր հյուրերին առաջարկում ենք հարմարավետ և հաճելի հանգիստ մեր գեղեցիկ ձևավորված սենյակներում: Յուրաքանչյուր սենյակ խնամքով ձևավորվել է հարմարավետ և հյուրընկալ մթնոլորտ ստեղծելու համար՝ ապահովելով, որ այցելության ընթացքում դուք ձեզ  զգաք ինչպես տանը:
                        Նվիրեք քեզ հաճույք արեք սենյակի հարմարություններով, ներառյալ լավ հագեցած մինի բարը, որը կատարյալ է ցանկացած պահի ձեր սիրած ըմպելիքները վայելելու համար: Բացի այդ, մեր ընդարձակ և ժամանակակից լոգասենյակներն ունեն այն ամենը, ինչ ձեզ հարկավոր է, ներառյալ զուգարանն ու թարմացնող ցնցուղը:
                        `,
            roominform:`«Բարի գալուստ մեր հյուրանոց: Զգացեք հարմարավետություն և հարմարավետություն մեր հմայիչ հյուրանոցում, որտեղ յուրաքանչյուր մանրուք հարմարեցված է մեր հյուրերի համար հաճելի հանգիստ ապահովելու համար: Մեր հարմարավետ սենյակները տեղավորում են 2-ից 3 հոգի, իդեալական զույգերի կամ փոքր ընտանիքների համար, որոնց գրանցումը կատարվում է ժ. Ժամը 14:00-ին և դուրս գալը՝ ժամը 12:00-ին: Վայելեք անվճար գերարագ Wi-Fi-ը սրճարանում և բարում: Հարմարավետ տեղակայված Երևանի կենտրոնում՝ մեր հյուրանոցն առաջարկում է հեշտ մուտք դեպի տեղական տեսարժան վայրեր, գնումների և ճաշի տարբերակներ: Մենք անհամբեր սպասում ենք ձեզ ողջունելու և ապահովելու ձեր հաճելի հանգիստը»:`,
            rinfo:`Սենյակի մասին տեղեկություններ`,
            occupy:`Առավելագույն զբաղվածություն`,
            checkin:`Ժամանում՝ 14:00`,
           chekout:`Դուրս գալ՝ 12:00`,
           free:`Անվճար`,
           included:`Նախաճաշը ներառված է`,
           hotelinfo:`Հյուրանոցային տեղեկատվություն`,
           CB:`Սրճարան և բար`,
           inroom:`Ճաշ սենյակում`,
           daily:`Ամենօրյա մաքրում`,
           center:`Կենտրոնական վայր`,
           tel:`Հեռախոս`,
           abus:`ՄԵՐ ՄԱՍԻՆ`,
           facilit:`Հնարավորություններ`,
           amenities:`Հարմարություններ`,
           condicioner:`Օդորակիչ`,
           minib:`Մինի բար`,
           location:`Գտնվելու վայրը`,
           adressx:`Հասցե`,
           menu:`Մենյու`,
           cocteils:`Կոկտեյլներ`,
           moxito:`Մոխիտո`,
           margarita:`Մարգարիտա`,
           cosmo:`Կոսմոպոլիտեն`,
           espmar:`Էսպրեսսո Մարտինի`,
           desert:`Աղանդեր`,
           brauni:`Շոկոլադե Բրաունի`,
           pirog:`Մրգային կարկանդակ`,
           vanil:`Վանիլային պաղպաղակ`,
           chokolad:`Շոկոլադե պաղպաղակ`,
           Strawberry:`Ելակի պաղպաղակ`,
           hotdr:`Տաք ըմպելիքներ`,
           espreso:`Էսպրեսսո`,
           americano:`Ամերիկանո`,
           Cappuccino:`Կապուչինո`,
           Latte:`Լատտե`,
           enlishT:`Անգլերեն նախաճաշ թեյ`,
           greenT:`Կանաչ թեյ`,
           nonAlc:`Ոչ ալկոհոլային`,
           orangeJ:`Նարնջի հյութ`,
           appleJ:`Խնձորի հյութ`,
           ananas:`Արքայախնձորի հյութ`,
           welc:`Բարի գալուստ`,
           infobintr:`Երևանի աշխույժ կենտրոնի սրտում գտնվող Avenue 23 Boutique Hotel-ն առաջարկում է հմայիչ հանգստավայր խորաթափանց ճանապարհորդների համար: Մտեք մեր գեղեցիկ տարածք, որտեղ ժամանակակից նրբագեղությունը հանդիպում է հայկական հյուրընկալությանը:

                      Անկախ նրանից, թե դուք փնտրում եք հանգիստ երեկո, թե աշխույժ հավաք ընկերների հետ, Avenue 23-ը հրավիրում է ձեզ ստեղծելու մնայուն հիշողություններ քաղաքի սրտում:`,
           bacK:`Բարի գալուստ մեր Բացօթյա Սրճարան`,
           injoi:`Վայելե՛ք հանգստացնող մթնոլորտ և համեղ ուտեստներ հենց մեր հյուրանոցի դիմաց:`,
           delishos:`Մեր Համեղ Ճաշացանկը`,
           closeMenu:`Փակել Ճաշացանկը`,
           openMenu:`Բացել Ճաշացանկը`,
           lunch:`Ճաշ`,
           dinner:`Ընթրիք`,
           Sausages:`Երշիկեղեն`,
           Fried:`Տապակած ձու`,
           Light:`Թեթև աղցաններ`,
           Pancakes:`Բլիթներ`,
           Grilled:`Խորոված հավի կրծքամիս`,
           Pasta:`Մակարոնեղեն`,
           Salads:`Աղցաններ`,
           Pizzas:`Պիցցաներ`,
           Sandwiches:`Սենդվիչներ`,
           Caesar:`Կեսարի աղցան`,
           adventure:`Սկսեք ձեր արկածը այստեղից`,
           LHBT:`ՇՔԵՂ ՀՅՈՒՐԱՆՈՑ ԵՎ ԼԱՎԱԳՈՒՅՆ ՏՈՒՐԵՐ`,
           moments:`ՎԱՅԵԼԵՔ ԿՅԱՆՔԻ ԼԱՎԱԳՈՒՅՆ ՊԱՀԵՐԸ`,


          },
        },
      },
});

// const handleLanguageChange = (selectedLanguage) => {
//     i18n.changeLanguage(selectedLanguage); // Update the language in i18n
//     localStorage.setItem('language', selectedLanguage); // Save the selected language to local storage
//   };

export default i18n;    